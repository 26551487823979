import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
//===============================================================================================================
export const defaultRoutes=[    
        {
            path:'/',
            redirect:'/index'
        },
        {
            path:'/index',
            name:'index',
            component:()=>import('../views/index.vue')
        }
]
//===============================================================================================================
export const asyncRouter=[
    {
        path:"/index",
        name:"index",
        meta:{title:"首页",icon:"images/icons/10140_24.png"},
        redirect:"/Health",
        component:()=>import("../views/index.vue"),
        children:[
            {
                path:'/Health',
                name:'Health',
                meta:{title:'我的主页',icon:'images/icons/40054_16.png'},
                redirect:"",
                component:resolve => require(['../views/home/Health.vue'],resolve)
            },                                  
        ]
    },      
]
//===============================================================================================================
const createRouter = () => new VueRouter({
    // mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: defaultRoutes
})
const router = createRouter()
//===============================================================================================================
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

//===============================================================================================================
router.beforeEach(function(to,from,next){
    next(); 
    // var loginstate=sessionStorage.getItem('loginstate'); 
    // var lastpage=sessionStorage.getItem('lastpage'); 
    // if(loginstate!='0' && loginstate!='1') loginstate='0';

    // if(to.path != '/' && to.path != '/login' && loginstate=='1' )
    // {
    //     sessionStorage.setItem('loginstate',"0");        
    //     resetAddRoute();
    //     // store.commit('domRouteTree',rousterArr)//存储进vuex，之后页面左右路由列表渲染使用
    //     next({...to,replace:true}) //进行路由跳转  
    // }
    // else
    // {
    //     if(to.path == '/index' && loginstate=='0')
    //     {
    //         next("/index");
    //     }
    //     else
    //     {
    //         if(lastpage==to.path)
    //         {
    //             //相同页面，等同于刷新
    //             // console.log("页面被刷新="+to.path);
    //             var resetload=sessionStorage.getItem('resetroute');
    //             if(resetload=='1')
    //             {
    //                 sessionStorage.setItem('resetroute',"0");
    //                 // console.log("重载动态路由...");
    //                 resetAddRoute();                
    //                 next({...to,replace:true}); //进行路由跳转                                        
    //             }
    //             else
    //             {
    //                 // console.log("刷新跳转...");
    //                 sessionStorage.setItem('resetroute',"1");
    //                 next(); 
    //             }  
    //         }
    //         else
    //         {
    //             sessionStorage.setItem('resetroute',"1");
    //             // console.log("页面首次加载="+to.path);
    //             next();
    //             sessionStorage.setItem('lastpage',to.path);
    //         } 
    //     }    
    // }    
})

// //===============================================================================================================
// router.beforeEach(function(to,from,next){
//     var loginstate=sessionStorage.getItem('loginstate'); 
//     if(loginstate!='0' && loginstate!='1') loginstate='0';

//     if(to.path != '/' && to.path != '/login' && loginstate=='1' )
//     {
//         sessionStorage.setItem('loginstate',"0");        
//         resetAddRoute();
//         // store.commit('domRouteTree',rousterArr)//存储进vuex，之后页面左右路由列表渲染使用
//         next({...to,replace:true}) //进行路由跳转  
//     }
//     else
//     {
//         if(to.path == '/index' && loginstate=='0')
//         {
//             next("/login");
//         }
//         else
//         {
//             console.log("window.performance.navigation.type="+window.performance.navigation.type);
//             //navigation.type
//             if (window.performance.navigation.type == 1) 
//             {            
//                 console.log("页面被刷新="+to.path);
                
//                 var resetload=sessionStorage.getItem('resetload');
//                 if(resetload=='0')
//                 {
//                     sessionStorage.setItem('resetload',"1");
//                     console.log("重载动态路由...");
//                     resetAddRoute();                
//                     next({...to,replace:true}); //进行路由跳转                    
//                     sessionStorage.setItem('resetload',"0");
//                 }
//                 else
//                 {
//                     console.log("刷新跳转...");
//                     next(); 
//                 }                
//             }
//             else
//             {
//                 sessionStorage.setItem('resetload',"0");
//                 console.log("页面首次加载="+to.path);
//                 next();
//             }   
//         }    
//     }    
// })
//===============================================================================================================
function resetAddRoute()
{
    var accountroute=sessionStorage.getItem("accountroute");    
    
    if(accountroute!='')
    {
        var obj = JSON.parse(accountroute);
        var route=[];
        for (const i in obj)
        {
            var item=createrouteitem(obj[i]);
            route.push(item);
        }        
        // router.addRoutes(route); 
        route.forEach(res=>{router.addRoute(res);})               
    }
}
//===============================================================================================================
function loadView(view)
{
    // 路由懒加载
    return (resolve) => require([`@/views/${view}`], resolve);
}
//===============================================================================================================
function createrouteitem(source)
{
    var item={path:'',name:'',meta:{title:'',icon:''},redirect:'',component:Object,children:Array};
    
    item.path=source.path;
    item.name=source.name;
    item.meta.title=source.meta.title;
    item.meta.icon=source.meta.icon;
    if(source.redirect)
    {
        item.redirect=source.redirect;
    }     
    item.component = loadView(source.component);    
    item.children=[];
    if(source.children)
    {
        if(source.children.length>0)
        {
            
            var children=[];
            for (const i in source.children)
            {
                var result=createrouteitem(source.children[i]);                
                children.push(result);
            }
            
            item.children=children;
        }   
    }
    return(item);
}

export default router
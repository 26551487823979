import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import Fragment from 'vue-fragment';
// import VuePrintnb from 'vue-print-nb';
import Print from '@/components/Lib/print.js'

Vue.use(ElementUI);
Vue.config.productionTip=false;
Vue.prototype.$echarts = echarts;
Vue.use(Fragment.Plugin);
// Vue.use(VuePrintnb);
Vue.use(Print);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
